<template>
    <div class="field-table flex-1 flex flex-col max-h-full">
        <sub-header buttonText="Create client" :handleClick="createNewClient" :showBtn="true" />
        <div class="flex flex-1 flex-col flex-grow max-h-full max-w-full overflow-auto scroll-bar">
            <span v-if="isLoading" class="flex h-44 flex-col min-w-full mt-6 bg-white items-center justify-center rounded-md">
                <loader class="my-5" height="32px" width="32px" :loading="isLoading" />
                Loading
            </span>
            <dv-table :showFilters="true" :showSorting="true" v-else :headers="columns" :rows="getFilteredResult" :handleRowClick="viewClientInfo" :showFooter="false" :isClickable="true">
                <template v-slot:items="{ row }">
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {{ row.name }}
                    </td>
                    <!-- <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <span class="inline-flex rounded-sm  px-2 text-xs font-semibold leading-5 "
                        :class="row.is_custom ? 'bg-green-100 text-green-800' : 'bg-gray-200 text-gray-800' ">{{row.is_custom? 'Custom': 'system Generated'}}</span>
                </td> -->
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <!-- <font-awesome-icon icon="input-text" class="icon alt" />  -->
                        {{ row.prefix }}</td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 flex justify-center">
                        <button @click.stop="displayWorkAllocationModal('teams', row.id)" class="flex flex-col gap-1 items-center relative  cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" :fill="row && row.work_allocation && row.work_allocation.users > 0 ? 'rgba(59, 130, 246,1)' : 'gray'" viewBox="0 0 640 512">
                                <!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                <path
                                    d="M72 88a56 56 0 1 1 112 0A56 56 0 1 1 72 88zM64 245.7C54 256.9 48 271.8 48 288s6 31.1 16 42.3V245.7zm144.4-49.3C178.7 222.7 160 261.2 160 304c0 34.3 12 65.8 32 90.5V416c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V389.2C26.2 371.2 0 332.7 0 288c0-61.9 50.1-112 112-112h32c24 0 46.2 7.5 64.4 20.3zM448 416V394.5c20-24.7 32-56.2 32-90.5c0-42.8-18.7-81.3-48.4-107.7C449.8 183.5 472 176 496 176h32c61.9 0 112 50.1 112 112c0 44.7-26.2 83.2-64 101.2V416c0 17.7-14.3 32-32 32H480c-17.7 0-32-14.3-32-32zm8-328a56 56 0 1 1 112 0A56 56 0 1 1 456 88zM576 245.7v84.7c10-11.3 16-26.1 16-42.3s-6-31.1-16-42.3zM320 32a64 64 0 1 1 0 128 64 64 0 1 1 0-128zM240 304c0 16.2 6 31 16 42.3V261.7c-10 11.3-16 26.1-16 42.3zm144-42.3v84.7c10-11.3 16-26.1 16-42.3s-6-31.1-16-42.3zM448 304c0 44.7-26.2 83.2-64 101.2V448c0 17.7-14.3 32-32 32H288c-17.7 0-32-14.3-32-32V405.2c-37.8-18-64-56.5-64-101.2c0-61.9 50.1-112 112-112h32c61.9 0 112 50.1 112 112z"
                                />
                            </svg>
                            <span class="text-gray-500 text-2xs font-semibold">Teams</span>
                            <span v-if="row && row.work_allocation && row.work_allocation.groups > 0" class="absolute -top-3 -right-2 w-5 h-5 text-2xs flex justify-center items-center rounded-full bg-blue-400 p-2 text-white-text">
                                {{ checkIfAllocationValueIsLarge(row.work_allocation.groups) }}
                            </span>
                        </button>
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <button @click.stop="displayWorkAllocationModal('users', row.id)" class="flex flex-col gap-1 items-center relative ml-10 cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" :fill="row && row.work_allocation && row.work_allocation.users > 0 ? 'rgba(59, 130, 246,1)' : 'gray'" viewBox="0 0 448 512">
                                <!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                <path d="M224 256A128 128 0 1 1 224 0a128 128 0 1 1 0 256zM209.1 359.2l-18.6-31c-6.4-10.7 1.3-24.2 13.7-24.2H224h19.7c12.4 0 20.1 13.6 13.7 24.2l-18.6 31 33.4 123.9 36-146.9c2-8.1 9.8-13.4 17.9-11.3c70.1 17.6 121.9 81 121.9 156.4c0 17-13.8 30.7-30.7 30.7H285.5c-2.1 0-4-.4-5.8-1.1l.3 1.1H168l.3-1.1c-1.8 .7-3.8 1.1-5.8 1.1H30.7C13.8 512 0 498.2 0 481.3c0-75.5 51.9-138.9 121.9-156.4c8.1-2 15.9 3.3 17.9 11.3l36 146.9 33.4-123.9z" />
                            </svg>
                            <span class="text-gray-500 text-2xs font-semibold">Users</span>
                            <span v-if="row && row.work_allocation && row.work_allocation.users > 0" class="absolute -top-3 -right-2 w-5 h-5 text-2xs flex justify-center items-center rounded-full bg-blue-400 p-2 text-white-text">
                                {{ checkIfAllocationValueIsLarge(row.work_allocation.users) }}
                            </span>
                        </button>
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {{ row.retention_period }}
                    </td>
                    <td class="whitespace-nowrap h-full px-3 py-4 text-sm text-gray-500">
                        <div class="flex justify-between items-center m-0">
                            {{ row.id.substring(0, 8) }}
                            <font-awesome-icon icon="angle-right" class="text-primary w-32 text-2xl self-center cursor-pointer hover:opacity-70" />
                        </div>
                    </td>
                </template>
                <template v-slot:no-data> </template>
            </dv-table>
            <DisplayWorkAllocationModal :workData="activeWorkAllocation" ref="work-allocation" :isLoading="fetchingWorkAllocation" />
        </div>
    </div>
</template>

<script>
// import Input from "@/components/input";
import axios from "@/axios";
// import Button from "@/components/button";
import SubHeader from "@/components/SubHeader";
import dvTable from "@/components/devliumComponent/field-table/dv-table";
// import { columns, rows } from "./fieldTableData";
// import Button from "@/components/button";
import loader from "@shared/loader";
import DisplayWorkAllocationModal from "@/components/create-client/displayWorkAllocationModal";

export default {
    name: "clients-list",
    components: {
        loader,
        SubHeader,
        dvTable,
        DisplayWorkAllocationModal,
    },
    data() {
        return {
            columns: [
                { label: "Client name", field: "name", config: { filter: true, type: "search", sort: true } },
                { label: "Prefix", field: "prefix", config: { filter: true, type: "search", sort: true } },
                { label: "Allocated Team", field: "allocated_teams" },
                { label: "Allocated Users", field: "allocated_users" },
                { label: "Retention period", field: "retention_period", config: { filter: true, type: "select", searchType: "number", sort: true } },
                { label: "Client id", field: "id", config: { filter: true, type: "search", sort: true } },
            ],
            isLoading: false,
            clients: [],
            fetchingWorkAllocation: false,
            activeWorkAllocation: {
                type: null,
                data: null,
            },
        };
    },
    async mounted() {
        if (!this.$store.getters.getTenantId) {
            await this.$store.dispatch("fetchTenantId");
        }
        await this.fetchClients();
    },
    title: "Clients",
    methods: {
        async fetchClients() {
            const tenant_id = this.$store.getters.getTenantId;
            this.isLoading = true;

            const data = await axios.get(`/tenant/${tenant_id}/client`);
            this.clients = data.data;
            this.isLoading = false;

            // this.columns =

            // {
            //     params:{
            //         id : this.$store.getters.getTenantId,
            //     }
            // }
            // )
            console.log(data);
        },
        viewClientInfo(data) {
            console.log(data, "data");
            this.$router.push({
                name: "client-details",
                params: { clientId: data.id },
            });
        },
        createNewClient() {
            this.$router.push({
                name: "create-client",
            });
        },
        async displayWorkAllocationModal(allocationType, client_id) {
            this.$refs["work-allocation"].showWorkModal();
            this.fetchingWorkAllocation = true;
            if (allocationType === "teams") {
                this.activeWorkAllocation = {
                    type: allocationType,
                    data: await this.fetchAllotedTeamsList(client_id),
                };
            } else {
                this.activeWorkAllocation = {
                    type: allocationType,
                    data: await this.fetchALlotedUsersList(client_id),
                };
            }
            this.fetchingWorkAllocation = false;
            this.$forceUpdate();
        },
        async fetchALlotedUsersList(client_id) {
            let url = `work-allocation/users/tenant?filter=alloted&&client_id=${client_id}`;
            let users = [];
            try {
                const { data } = await axios.get(url);
                users = data;
            } catch (error) {
                console.log(error, "Error");
            }
            console.log(users, "users");
            return users;
        },
        async fetchAllotedTeamsList(client_id) {
            let url = `work-allocation/groups/tenant?filter=alloted&&client_id=${client_id}`;
            let teams = [];
            try {
                const { data } = await axios.get(url);
                teams = data;
            } catch (error) {
                console.log(error, "Erro");
            }
            console.log(teams, " teams");
            return teams;
        },
        checkIfAllocationValueIsLarge(allocationValue) {
            if (allocationValue > 9) {
                return "9+";
            } else {
                return allocationValue;
            }
        },
    },
    computed: {
        getFilteredResult() {
            console.log(this.clients);
            return this.clients;
        },
    },
};
</script>

<style scoped></style>
