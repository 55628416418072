import { render, staticRenderFns } from "./displayWorkAllocationModal.vue?vue&type=template&id=4eb1afe2&scoped=true"
import script from "./displayWorkAllocationModal.vue?vue&type=script&lang=js"
export * from "./displayWorkAllocationModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4eb1afe2",
  null
  
)

export default component.exports